"use client"

import Input from "@/components/forms/Input";
import Style from "./LocalizeWidget.module.scss"
import { IconLocation } from "@/icons/IconLocation";
import { useContext, useState } from "react";
import Button from "@/components/forms/Button";
import WidgetBody from "@/components/display/WidgetBody";
import useLocateMe from "@/global_hooks/useLocateMe";
import ButtonFeedback from "@/components/forms/Button/ButtonFeedback";
import useButtonFeedback from "@/components/forms/Button/useButtonFeedback";
import { GlobalContext } from "@/contexts/GlobalContext";


function LocalizeWidget(){
    const [ postalCode, setPostalCode ] = useState("")
    const location = useLocateMe()
    const feedback = useButtonFeedback()
    const [ _, dispatch ] = useContext(GlobalContext);

    function updatePostal(){
        location.usePostalCode(postalCode)
    }

    function updateCurrentLocation(){
        feedback.setLoading(true)

        location.useCurrentLocation(() => {
            feedback.setLoading(false)

            dispatch({
                type: "setWidget",
                data: {
                    isOpen: false,
                    component: null
                }
            })
        })
    }

    return(
        <WidgetBody 
            className={Style.content}
            actions={
                <Button color="ocean" type="button" onClick={updatePostal}>
                    Update Shipping
                </Button>
            }
        >
            <p>Inventory and shipping/delivery options may change based on your location</p>

            <div>
                <Input 
                    className={Style.input}
                    label="Enter Postal Code"
                    name="postalCode"
                    placeholder="e.g. 6529"
                    onChange={(e) => setPostalCode(e.target.value)}
                    autoFocus
                />
            </div>

            <p className={Style.or} />

            <button 
                className={Style.myLocationBtn}
                type="button"
                onClick={updateCurrentLocation}
            >
                <ButtonFeedback>
                    <IconLocation width="30" height="30" />
                    Use Your Current Location
                </ButtonFeedback>
            </button>
        </WidgetBody>
    )
}





export default LocalizeWidget;