"use client"

import clsx from "clsx";
import Style from "./Localize.module.scss";
import { IconLocation } from "@/icons/IconLocation";
import useLocateMe from "@/global_hooks/useLocateMe";
import { useContext } from "react";
import { GlobalContext } from "@/contexts/GlobalContext";
import LocalizeWidget from "@/components/applications/shipping/LocalizeWidget";



function Localize({ theme }){
    const [ global, dispatch ] = useContext(GlobalContext)

    function toggleWidget(){
        dispatch({
            type: "setWidget",
            data: {
                isOpen: true,
                component: <LocalizeWidget />,
                heading: "Update Location"
            }
        })
    }
    
    const location = useLocateMe()



    return(
        <button 
            type="button" 
            className={clsx(Style.block, Style[theme])}
            onClick={toggleWidget}
            title={location?.data?.text +", "+ location?.data?.context?.[0]?.text}
        >
            <IconLocation className={Style.icon} />

            <div className={Style.location}>
                Ship to: {location?.data?.text ?? "George"}, {location?.data?.context?.[0]?.text ?? "6529"}
            </div>        
        </button>
    )
}

export default Localize;