import Card from "@/components/display/Card";
import Image from "next/image";
import Link from "next/link";
import Style from "./ProductCard.module.scss"
import createProductURL from "@/lib/utils/createProductURL";
import Price from "../Price";


function ProductRecommendation({ id, name, price, image, url, index = 1 }){
    return(
        <Card className={"keen-slider__slide"} noMargin>
            <Link href={createProductURL(url, id)} className={Style.link}>
                <Image 
                    src={image ?? `https://picsum.photos/300/300??random=${index}`} 
                    width={200} 
                    height={200} 
                    alt={name} 
                    className={Style.image}
                />

                <Price value={price} size="sm" />

                <p className={Style.itemTitle}>
                    {name}
                </p>
            </Link>
        </Card>
    )
}

export default ProductRecommendation;