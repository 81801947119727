"use client"

import Footer from "@/components/applications/header-footer/Footer";
import Header from "@/components/applications/header-footer/Header";
import Grid from "@/components/layout/Grid";
import GridColumn from "@/components/layout/GridColumn";
import Utils from "@/styles/globals/utils.module.scss";
import clsx from "clsx";

function Error({ statusCode, err }){
    return(
        <>
            <Header />

            <main className={Utils.container}>
                <Grid>
                    <GridColumn start={2} end={14} className={clsx(Utils.pt_6, Utils.text_center)}>
                        <h1 className={Utils.fs_1}>
                            {statusCode || err} Error. 🥺
                        </h1>
                        
                        <p className={Utils.text_lead}>Looks like something unexpected just happened, but we have been notified and will take a look shortly.</p>
                    </GridColumn>
                </Grid>
            </main>

            <Footer />
        </>
    )
}


export default Error;