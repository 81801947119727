import Link from "next/link";
import Style from "./Header.module.scss"
import User from "./User";
import Cart from "./Cart";
import Menu from "./Menu";
import Search from "./Search";
import TopHat from "./TopHat";
import clsx from "clsx";
import Logo from "./Logo";
import UtilityBelt from "./UtilityBelt";


function Header({ theme = null, showSearch = true, showTopHat = true }){
    return(
        <>
            <header className={clsx(Style.block, Style[theme])}>
                <UtilityBelt theme={theme} />

                <div className={Style.top}>
                    <div className={Style.logoMenu}>
                        <Link href="/" className={Style.logo}>
                            <Logo {...{ theme }} />
                        </Link>

                        <Menu {...{ theme }} />


                         <nav className={Style.nav}>
                            <ul className={Style.navList}>
                                <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                                    <Link href="/b/deals" className={Style.mainLink}>
                                    Deals
                                    </Link>
                                </li>

                                <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                                    <Link href="/b/new" className={Style.mainLink}>
                                        What's New!
                                    </Link>
                                </li>
                            </ul>
                        </nav>


                        <div className={clsx(Style.cartMobile)}>
                            <Cart {...{ theme }} />
                        </div>
                    </div>


                    {showSearch && (
                        <Search />
                    )}
                    

                    <ul className={Style.navList}>
                        <li className={Style.navItem}>
                            <User {...{ theme }} />
                        </li>
                        

                        <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                            <Cart {...{ theme }} />
                        </li>
                    </ul>
                </div>
            </header>
            
            {showTopHat && (
                <TopHat {...{ theme }} />
            )}
        </>
    )
}


export default Header;