import Style from "./UtilityBelt.module.scss"
import { Suspense } from "react";
import Loader from "@/components/feedback/Loader";
import Localize from "../Localize";
import Language from "../Language";
import clsx from "clsx";
import Link from "next/link";


function UtilityBelt({ theme }){
    return(
        <ul className={Style.block}>
            <ul className={Style.utils}>
               <li className={Style.item}>
                    <Suspense fallback={<Loader isLoading />}>
                        <Localize {...{ theme }} />
                    </Suspense>
                </li>
                        
                <li className={Style.item}>
                    <Language {...{ theme }} />
                </li>
            </ul>

            <ul className={Style.navList}>
                <li className={clsx(Style.item, Style[`nav__${theme}`])}>
                    <Link href="/l/purchase-assurance" className={Style.itemLink} title="Shop with confidence">
                        Purchase Assurance
                    </Link>
                </li>

                <li className={clsx(Style.item,  Style[`nav__${theme}`])}>
                    <Link href="/l/become-a-vendor" className={Style.itemLink} title="Start selling">
                        Become a vendor
                    </Link>
                </li>

                <li className={clsx(Style.item,  Style[`nav__${theme}`])}>
                    <Link href="/help" className={Style.itemLink} title="Get Help">
                        Help Center
                    </Link>
                </li>
            </ul>
        </ul>
    )
}

export default UtilityBelt;